import React, { Component } from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero"

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="Revendeur sur&nbsp;le marché français (H/F) - ExpertSender" description="Revendeur sur le marché français (H/F)" />
        <Hero title="Revendeur sur&nbsp;le marché français (H/F)" button="false" classes="heroes--full has-text-centered" />

        <div className="container container--pad pad-t-2 pad-b-6 mar-t-6 mar-b-6">
          <div className="columns">
            <div className="column" />
            <div className="column is-two-thirds">
              <div className="columns is-centered">
                <div className="post__content content column">
                  <p>Au cours des derniers mois, le marché du e-commerce en France et en Europe s'est accéléré plus rapidement que prévu. Afin de suivre le rythme, nous recherchons des Revendeurs sur le marché français avec leur propre réseau de contacts, une expérience technologique dans le e-mail marketing, le domaine des boutiques en ligne, des réseaux commerciaux et des applications mobiles. En tant que Revendeur, vous travaillerez en étroite collaboration avec nos départements chez ExpertSender HQ.</p>
                  <h4>Qu’allez-vous faire?</h4>
                  <ul>
                    <li>Identifier les clients potentiels de l'industrie du e-commerce et du SaaS, à la fois en France et dans d'autres pays européens.</li>
                    <li>Identifier les besoins des clients potentiels et leur présenter la portée d'ExpertSender.</li>
                    <li>Présenter les fonctions et les capacités d'ExpertSender aux clients potentiels en fonction des documents fournis par le département de marketing.</li>
                    <li>Organiser de la présentation de la plateforme ExpertSender aux clients potentiels en collaboration avec ExpertSender HQ.</li>
                    <li>Collecter des données nécessaires pour envoyer l'offre à un client potentiel.</li>
                    <li>Compléter des données indiquées des clients potentiels dans le système CRM MS Dynamics.</li>
                  </ul>
                  <h4>Si vous êtes une entreprise:</h4>
                  <ul>
                    <li>avec une expérience commerciale dans le domaine du e-commerce / SaaS / Digital;</li>
                    <li>appréciant l'indépendance dans l'action et la flexibilité de la coopération;</li>
                    <li>avec des contacts étendus (dans les départements marketing, C-level ou strictement e-commerce et le vente au détail) dans l'industrie du e-commerce / SaaS / Digital, à la fois du côté des clients potentiels (magasins en ligne) et des entités qui peuvent apporter de tels contacts;</li>
                    <li>prêt à connaître les dernières tendances de MarTech;</li>
                    <li>avec des connaissances dans le domaine du marketing Digital, MarTech (avantage supplémentaire), e-commerce;</li>
                    <li>qui a la capacité de connecter des contacts de diverses industries et de les maintenir;</li>
                    <li>avec une maîtrise professionnelle de l'anglais,</li>
                  </ul>
                  <p>… Nous attendons votre candidature!</p>
                  <p>&nbsp;</p>
                  <h4>Nous pouvons vous garantir:</h4>
                  <ul>
                    <li>une rémunération de commission supérieure à la moyenne;</li>
                    <li>une coopération stable avec un partenaire bien établi sur le marché depuis plus de 11 ans;</li>
                    <li>l’accès aux dernières technologies dans le domaine de MarTech et du marketing basé sur les données;</li>
                    <li>une trousse de formation complète et du matériel de marketing pour soutenir les ventes.</li>
                  </ul>
                  <h3>Intéressé(e)?</h3>
                  <a href="mailto:contact@expertsender.fr?cc=praca@expertsender.com&subject=Revendeur%20sur%20le%20marché%20français%20" className="button is-medium is-pink mar-b-2" style={{ marginRight: '0.75em' }}>Postuler</a>
                  <p className="mar-b-1">Si vous souhaitez que vos données soient traitées à des fins de recrutement, veuillez inclure la clause suivante dans votre CV. Le consentement suivant est volontaire. Vous pouvez le retirer à tout moment en envoyant une lettre au siège social d’ExpertSender Sp. z o. o. ou en envoyant un e-mail à gdpr@expertsender.com. Veuillez garder à l’esprit que cela n’affecte pas la légalité du traitement qui a été effectué avant le retrait du consentement.</p>
                  <p>
                    <em>Par la présente, j’autorise le traitement par l’administrateur, c’est-à-dire ExpertSender Sp. Z o. o. dont le siège social est situé à Gdańsk (code postal : 80-280), ul. Norwida 1, inscrit au registre des entrepreneurs du Registre Judiciaire National, tenu par le Tribunal du district de Gdańsk – Północ-Gdańsk, 8e Division commerciale, sous le numéro d’inscription (KRS) : 0000329885, Numéro d’identification fiscale (NIP) : 5862237116, n° statistique (REGON) : 220775305, au capital social de 50 000 PLN, de mes données personnelles indiquées dans le formulaire de candidature que j'ai moi-même soumis, à savoir prénom ou prénoms et nom, noms des parents, date de naissance, lieu de résidence ou adresse de correspondance, formation et expérience professionnelle, à des fins de participation au processus de recrutement pour le poste pour lequel je soumets ma candidature, pour une période de 6 mois à partir de la date de soumission de la candidature. Par la présente, je déclare que l’expression de mon consentement est volontaire et n’affecte pas la possibilité de participer au recrutement. Son retrait n'entraînera aucune conséquence négative et n'affectera pas la légalité du traitement qui a été effectué avant ledit retrait.</em>
                  </p>
                </div>
              </div>
            </div>
            <div className="column" />
          </div>
        </div>
      </Layout>
    )
  }
}

export default SuccessStories
